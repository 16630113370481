<template>
  <div class="setting-area">
    <div class="pilihan-radar">
      <label for="pilih-radar">Pilih radar: </label>
      <select name="radars" class="pilih-radar" @change="pilihArea($event)">
        <option value="">--Silahkan pilih radar--</option>
        <option
          v-for="radar in radarAktif"
          :key="radar['radar_id']"
          :value="radar['radar_id']"
        >
          {{ radar['radar_name'] }}
        </option>
      </select>
    </div>
    <!-- <svg>
      <path fill="none" stroke="green" stroke-width="4" :d="d" />
    </svg> -->
    <div id="chart-area-terdampak"></div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
// import * as d3 from 'd3'
import Plotly from 'plotly.js-dist-min'

export default {
  setup() {
    const store = useStore()

    const radarAktif = computed(() => store.getters.defaultRadarAktif)

    const grupAreaPantauan = computed(() => store.getters.grupAreaPantauan)

    const waktuGambarRadar = computed(() => store.getters.waktuGambarRadar)

    const radarTerpilih = ref()

    watch(waktuGambarRadar, () => {
      if (radarTerpilih.value != null) {
        axios.get(`/monitored-area-data?radar=${radarTerpilih.value}`)
          .then(({ data }) => {
            const dataInput = data.reduce(function (r, a) {
                r[a.area_name] = r[a.area_name] || {nilaiMean: [], nilaiWaktu: [], color: ''}
                r[a.area_name]["nilaiMean"].push(a.mean)
                r[a.area_name]["nilaiWaktu"].push(a.datetime)
                r[a.area_name]["color"] = a.color
                return r
            }, Object.create(null));

            const namaArea = Object.keys(dataInput)

            const dataAreaTerdampak = []

            for (let index in namaArea) {
              let tempObj = {
                type: 'scatter',
                mode: 'lines',
                line: {}
              }
              tempObj.name = namaArea[index]
              tempObj.x = dataInput[namaArea[index]].nilaiWaktu
              tempObj.y = dataInput[namaArea[index]].nilaiMean
              tempObj.line.color = dataInput[namaArea[index]].color
              
              dataAreaTerdampak.push(tempObj)
            }

            const layout = {
              title: 'Daerah Terdampak',
              // autosize: true,
              margin: {
                l: 20,
                r: 20,
                b: 30,
                t: 50
                // pad: 4
              },
              yaxis: {
                title: 'Curah Hujan (dBz)',
                automargin: true,
                titlefont: { size: 14 }
              },
              xaxis: {
                title: 'Waktu',
                automargin: true,
                titlefont: { size: 14 }
              }
            }

            const config = {
              responsive: true,
              displayModeBar: false, // this is the line that hides the bar.
            }

            Plotly.newPlot('chart-area-terdampak', dataAreaTerdampak, layout, config)
          })
      }
    })

    const pilihArea = (event) =>{
      grupAreaPantauan.value.clearLayers()
      radarTerpilih.value = event.target.value

      if (radarTerpilih.value != "") {
        store.dispatch('tambahAreaPantauan', radarTerpilih.value)
        axios.get(`/monitored-area-data?radar=${radarTerpilih.value}`)
          .then(({ data }) => {
            const dataInput = data.reduce(function (r, a) {
                r[a.area_name] = r[a.area_name] || {nilaiMean: [], nilaiWaktu: [], color: ''}
                r[a.area_name]["nilaiMean"].push(a.mean)
                r[a.area_name]["nilaiWaktu"].push(a.datetime)
                r[a.area_name]["color"] = a.color
                return r
            }, Object.create(null));

            // console.log(dataInput)

            const namaArea = Object.keys(dataInput)

            const dataAreaTerdampak = []

            for (let index in namaArea) {
              let tempObj = {
                type: 'scatter',
                mode: 'lines',
                line: {}
              }
              tempObj.name = namaArea[index]
              tempObj.x = dataInput[namaArea[index]].nilaiWaktu
              tempObj.y = dataInput[namaArea[index]].nilaiMean
              tempObj.line.color = dataInput[namaArea[index]].color
              
              dataAreaTerdampak.push(tempObj)
            }

            const layout = {
              title: 'Daerah Terdampak',
              // autosize: true,
              margin: {
                l: 20,
                r: 20,
                b: 30,
                t: 50
                // pad: 4
              },
              yaxis: {
                title: 'Curah Hujan (dBz)',
                automargin: true,
                titlefont: { size: 14 }
              },
              xaxis: {
                title: 'Waktu',
                automargin: true,
                titlefont: { size: 14 }
              }
            }

            const config = {
              responsive: true,
              displayModeBar: false, // this is the line that hides the bar.
            }

            Plotly.newPlot('chart-area-terdampak', dataAreaTerdampak, layout, config)
          })
      }
    }

    return {
      radarAktif,
      pilihArea
    }
  }
}
</script>

<style>
.setting-area {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

/*
#pilih-radar {
  user-select: none;
}
*/

#chart-area-terdampak {
  width: 100%;
  height: 20rem;
}
</style>
