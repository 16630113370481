const getters = {
  mapDiv(state) {
    return state.mapDiv
  },
  defaultRadarAktif(state) {
    return state.defaultRadarAktif
  },
  objekRadarAktif(state) {
    return state.objekRadarAktif
  },
  tandaLokasiRadar(state) {
    return state.tandaLokasiRadar
  },
  grupAreaPantauan(state) {
    return state.grupAreaPantauan
  },
  grupAreaDAS(state) {
    return state.grupAreaDAS
  },
  grupGambarRadar(state) {
    return state.grupGambarRadar
  },
  grupHistoryGambarRadar(state) {
    return state.grupHistoryGambarRadar
  },
  waktuGambarRadar(state) {
    return state.waktuGambarRadar
  },
  statusQPE(state) {
    return state.statusQPE
  }
}

export default getters
