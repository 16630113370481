import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt, faChartArea, faEye, faHistory, faCrosshairs, faPlay, faPause, faForward, faBackward, faMapPin, faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";

// axios.defaults.baseURL = 'http://192.168.100.131/api/'
axios.defaults.baseURL = 'https://santanu.brin.go.id/api/'
// axios.defaults.baseURL = 'http://127.0.0.1/api/'

library.add(faMapMarkerAlt, faChartArea, faEye, faHistory, faCrosshairs, faPlay, faPause, faForward, faBackward, faMapPin, faLock, faEnvelope);

const app = createApp(App)

app.use(store)
app.use(router)

app.component("fa", FontAwesomeIcon);

app.mount('#app')
