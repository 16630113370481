<template>
  <div class="setting-opacity">
    <!-- <div class="opacity-box radar-opacity">
      <p>Opasitas penanda radar: {{ opacityIkonRadar }}</p>
      <input
        type="range"
        class="slider-opacity"
        min="0"
        max="100"
        v-model="opacityIkonRadar"
      />
    </div> -->
    <div class="opacity-box">
      <p>Opasitas gambar radar: {{ opacityGambarRadar }}</p>
      <input
        type="range"
        class="slider-opacity"
        min="0"
        max="100"
        v-model="opacityGambarRadar"
      />
    </div>
    <div class="opacity-box">
      <p>Opasitas jangkauan radar: {{ opacityJangkauanRadar }}</p>
      <input
        type="range"
        class="slider-opacity"
        min="0"
        max="100"
        v-model="opacityJangkauanRadar"
      />
    </div>
    <div class="opacity-box">
      <p>Opasitas area pantauan: {{ opacityAreaPantauan }}</p>
      <input
        type="range"
        class="slider-opacity"
        min="0"
        max="100"
        v-model="opacityAreaPantauan"
      />
    </div>
    <div class="opacity-box">
      <p>Opasitas DAS: {{ opacityDAS }}</p>
      <input
        type="range"
        class="slider-opacity"
        min="0"
        max="100"
        v-model="opacityDAS"
      />
    </div>
    <button
      class="btn btn-qpe realtime"
      :class="{ 'realtime-active': statusQPE, 'btn-qpe-disabled': isDisable }"
      @click="ubahStatusQPE()"
    >
      Tampilan QPE
    </button>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'

export default {
  setup() {
    const store = useStore()

    store.dispatch('tambahAreaDAS')

    const mapDiv = computed(() => store.getters.mapDiv)

    const statusQPE = computed(() => store.getters.statusQPE)
    const isDisable = ref(false)

    const ubahStatusQPE = () => {
      isDisable.value = true
      store.dispatch('ubahStatusQPE', !statusQPE.value)
      setTimeout(() => isDisable.value = false, 1000)
    }

    // const opacityIkonRadar = ref(85)
    // watch(opacityIkonRadar, (update) => {
    //   mapDiv.value.getPane('panelIkonRadar').style.opacity = update / 100
    // })

    const opacityGambarRadar = ref(85)
    watch(opacityGambarRadar, (update) => {
      mapDiv.value.getPane('panelGambarRadar').style.opacity = update / 100
    })

    const opacityJangkauanRadar = ref(5)
    watch(opacityJangkauanRadar, (update) => {
      mapDiv.value.getPane('panelJangkauanRadar').style.opacity = update / 100
    })

    const opacityAreaPantauan = ref(60)
    watch(opacityAreaPantauan, (update) => {
      mapDiv.value.getPane('panelAreaPantauan').style.opacity = update / 100
    })
    
    const opacityDAS = ref(0)
    watch(opacityDAS, (update) => {
      mapDiv.value.getPane('panelAreaDAS').style.opacity = update / 100
    })

    setTimeout(() => {
      // mapDiv.value.getPane('panelIkonRadar').style.opacity = opacityIkonRadar.value / 100
      mapDiv.value.getPane('panelGambarRadar').style.opacity = opacityGambarRadar.value / 100
      mapDiv.value.getPane('panelJangkauanRadar').style.opacity = opacityJangkauanRadar.value / 100
      mapDiv.value.getPane('panelAreaPantauan').style.opacity = opacityAreaPantauan.value / 100
      mapDiv.value.getPane('panelAreaDAS').style.opacity = opacityDAS.value / 100
    }, 100)

    return {
      ubahStatusQPE,
      statusQPE,
      isDisable,
      // opacityIkonRadar,
      opacityGambarRadar,
      opacityJangkauanRadar,
      opacityAreaPantauan,
      opacityDAS
    }
  }
}
</script>

<style>
.setting-opacity {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  padding: 1rem 2rem;
}

.opacity-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.slider-opacity {
  cursor: pointer;
}

.btn-qpe {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.24);
  outline: none;
  transition: 0.2s all;
}

.btn-qpe-disabled {
  transform: scale(0.98);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.24);
  pointer-events: none;
}
</style>
