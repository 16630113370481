import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Maintenance from '../views/Maintenance.vue'

const defaultTitle = "SANTANU";
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: "Home - SANTANU" },
    component: Home
    // component: Maintenance
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: "About - SANTANU" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/history',
    name: 'History',
    meta: { title: "History - SANTANU" },
    component: () => import('../views/History.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: "Login - SANTANU" },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: { title: "404 Not Found" },
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title ? to.meta.title : defaultTitle;
  }
});

export default router
