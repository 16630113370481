import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { toRaw } from "vue"
import axios from 'axios'

const mutations = {
  inisiasiLeaflet: (state, elemenDiv) => {
    state.mapDiv = L.map(elemenDiv, {
      attributionControl: false,
      // zoomAnimation:false,
      zoomControl: false
    }).setView(state.pusatMap, state.startZoom)

    const backgroundLayer = L.tileLayer(
      // 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      // 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      {
        maxZoom: state.maxZoom,
        minZoom: state.minZoom
        // id: 'mapbox/streets-v11',
        // accessToken: "pk.eyJ1Ijoicml2YS1mcmVtIiwiYSI6ImNsZm5rbGJidjBtZzQzc3J0YXhiNW83bzcifQ.-oJzSrIR0uwbqBSPzGTvUw"
      }
    )
    state.mapDiv.addLayer(backgroundLayer)
    // state.mapDiv.fitBounds(state.batasanMap)  // Tampilan awal yang terlihat
    state.mapDiv.setMaxBounds(state.batasanMap)

    // Membuat panel
    state.mapDiv.createPane('panelIkonRadar')
    state.mapDiv.createPane('panelJangkauanRadar')
    state.mapDiv.createPane('panelGambarRadar')
    state.mapDiv.createPane('panelAreaPantauan')
    state.mapDiv.createPane('panelAreaDAS')

    // Menetapkan z-index panel
    state.mapDiv.getPane('panelIkonRadar').style.zIndex = 620
    state.mapDiv.getPane('panelJangkauanRadar').style.zIndex = 590
    state.mapDiv.getPane('panelGambarRadar').style.zIndex = 610
    state.mapDiv.getPane('panelAreaPantauan').style.zIndex = 600
    state.mapDiv.getPane('panelAreaDAS').style.zIndex = 595

    // Membuat grup
    state.grupLokasiRadar = L.layerGroup().addTo(toRaw(state.mapDiv))
    state.grupJangkauanRadar = L.layerGroup().addTo(toRaw(state.mapDiv))
    state.grupAreaPantauan = L.layerGroup().addTo(toRaw(state.mapDiv))
    state.grupAreaDAS = L.layerGroup().addTo(toRaw(state.mapDiv))
  },
  setObjekRadarAktif: (state, objek) => (state.objekRadarAktif = objek),
  setGrupGambarRadar: (state, idRadar) => {
    state.grupGambarRadar[idRadar] = L.layerGroup().addTo(toRaw(state.mapDiv))
  },
  updateDefaultRadarAktif: (state, listRadar) => (state.defaultRadarAktif = listRadar),
  tandaiLokasiRadar: (state, [id, lokasi, kota, deskripsi]) => {
    state.tandaLokasiRadar[id] = L.circle(lokasi, {
      color: '#000000',
      opacity: 1,
      pane: 'panelIkonRadar'
    })
      .bindTooltip(`
        <div class="tooltip-lokasi-radar">
          <h3 class="tooltip-header">${kota}</h3>
          <p>${deskripsi}</p>
        </div>
      `)
      .addTo(toRaw(state.grupLokasiRadar))
      .on('click', function() {
        state.mapDiv.flyTo(lokasi, 10, { animate: false })
      })
  },
  tambahLatarJangkauanRadar: (state, [gambar, jangkauan]) => {
    L.imageOverlay(gambar, jangkauan, {
      opacity: 1,
      pane: 'panelJangkauanRadar'
    }).addTo(state.grupJangkauanRadar)
  },
  tambahGambarRadar: (state, [pathGambar, batasanGambar, idRadar, mode, time = 0]) => {
    if (mode == 3) {
      state.grupGambarRadar[idRadar].clearLayers()

      L.imageOverlay(
        pathGambar,
        batasanGambar,
        {
          pane: "panelGambarRadar",
          opacity: 1,
          // interactive: true,
        }
      ).addTo(state.grupGambarRadar[idRadar])

      return
    }

    let image = new Image()

    // mode 1 = tambah gambar tampa penyimpanan
    // mode 2 = tambah gambar dan disimpan dalam objek
    if (mode == 1) {
      image.onload = () => {
        L.imageOverlay(
          image,
          batasanGambar,
          {
            pane: "panelGambarRadar",
            opacity: 1,
            // interactive: true,
          }
        ).addTo(state.grupGambarRadar[idRadar])
      }

      image.src = pathGambar

      state.grupGambarRadar[idRadar].clearLayers()

      return
    }

    image.onload = () => {      
      if (mode == 2) {
        return state.grupHistoryGambarRadar[idRadar][time] = image
      }
    }

    image.src = pathGambar
  },
  updateWaktuGambarRadar: (state, waktu) => state.waktuGambarRadar = waktu,
  resetGrupHistoryGambarRadar: state => {
    const keyGrupHistoryGambarRadar = Object.keys(state.grupHistoryGambarRadar)
    const lenKey = keyGrupHistoryGambarRadar.length
    for (let item = 0; item < lenKey; item++) {
      state.grupHistoryGambarRadar[keyGrupHistoryGambarRadar[item]] = {}
    }
  },
  tambahAreaPantauan: (state, radar) => {
    axios.get(`/monitored-area?radar=${radar}`)
      .then(({ data }) => {
        for (let area in data) {
          L.geoJSON(JSON.parse(data[area].geojson), {pane: "panelAreaPantauan", color: data[area].color, opacity: 1})
            .bindTooltip(`
              <div class="tooltip-lokasi-radar">
                <p>${data[area].area_name}</p>
              </div>
            `)
            .addTo(toRaw(state.grupAreaPantauan))
        }
      })
  },
  tambahAreaDAS: (state) => {
    axios.get(`/das`)
      .then(({ data }) => {
        for (let index in data['features']) {
          L.geoJSON(data['features'][index], {pane: "panelAreaDAS", color: '#68B0CA', fillOpacity: 0, opacity: 1, interactive: false})
            .bindTooltip(`
              <div class="tooltip-lokasi-radar">
                <p>${data['features'][index]['properties']['DAS']}</p>
              </div>
            `)
            .addTo(toRaw(state.grupAreaDAS))
        }

        // console.log(data)
      })
  },
  ubahStatusQPE: (state, status) => state.statusQPE = status
}

export default mutations
