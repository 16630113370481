const actions = {
  updateZonaWaktu({ commit }, payload) {
    commit('updateZonaWaktu', payload)
  },
  updateWaktu({ commit }, payload) {
    commit('updateWaktu', payload)
  }
}

export default actions
