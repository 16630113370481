const getters = {
  zonaWaktu(state) {
    return state.zonaWaktu
  },
  waktuSaatIni(state) {
    return state.waktuSaatIni
  }
}

export default getters
