<template>
  <header class="header noselect">
    <router-link to="/">
      <img class="logo" alt="Santanu Logo" src="@/assets/logo-santanu.png" />
    </router-link>
    <h1 class="header-title">
      <span>SANTANU</span>
      <span>Sistem Pemantauan Hujan</span>
    </h1>
    <nav class="main-nav">
      <ul class="main-nav-list">
        <li><router-link to="/" class="main-nav-link">Home</router-link></li>
        <li>
          <router-link to="/about" class="main-nav-link">About</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<style>
.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem !important;
  height: 6rem;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: #E9FAE3;
  border-bottom: 2px solid rgba(0,0,0,.2);
}

.header a:link,
.header a:visited {
  display: flex;
  align-items: center;
}

.header .logo {
  display: block;
  height: 4rem;
}

.header h1 {
  font-size: 1.6rem;
}

.header-title span:first-of-type {
  display: inline-block;
}

.header-title span:last-of-type {
  display: none;
}

.main-nav {
  margin-left: auto;
}

.main-nav-list {
  display: flex;
  align-items: center;
  gap: 3rem;
  list-style: none;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #2a2b26;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #f1bf98;
}

@media (min-width: 650px) {
  .header-title span:first-of-type {
    display: none;
  }

  .header-title span:last-of-type {
    display: inline-block;
  }
}
</style>
