import { createStore } from 'vuex'
import map from './modules/map/index.js'
import waktu from './modules/waktu/index.js'

const store = createStore({
  modules: {
    map,
    waktu
  }
})

export default store