<template>
  <div class="setting-location">
    <p>Titik lokasi radar SANTANU:</p>
    <div class="radar-location">
      <button
        v-for="radar in dataLokasiRadar"
        :key="radar['radar_id']"
        @click="pilihLokasiRadar(radar['radar_lat'], radar['radar_lon'])"
        class="btn btn-location"
      >
        {{ radar['radar_name'] }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'
import axios from 'axios'

export default {
  setup() {
    const store = useStore()

    const mapDiv = computed(() => store.getters.mapDiv)
    const grupLokasiRadar = computed(() => store.getters.grupLokasiRadar)
    const grupJangkauanRadar = computed(() => store.getters.grupJangkauanRadar)
    const grupHistoryGambarRadar = computed(() => store.getters.grupHistoryGambarRadar)

    const fetchLokasiRadar = ref(null)
    axios.get('/radar-location')
      .then(({ data }) => {
        fetchLokasiRadar.value = data
        store.dispatch('updateDefaultRadarAktif', data)
      }).catch(() => {
        fetchLokasiRadar.value = store.getters.defaultRadarAktif
      })
    watch(fetchLokasiRadar, (update) => {
      if (grupLokasiRadar.value != null && grupJangkauanRadar.value != null) {
        grupJangkauanRadar.value.clearLayers()
        grupLokasiRadar.value.clearLayers()
      }

      // Latar jangkauan radar
      const canvas = document.createElement('canvas')
      canvas.setAttribute('width', 1000)
      canvas.setAttribute('height', 1000)
      const context = canvas.getContext('2d')
      const centerX = canvas.width / 2
      const centerY = canvas.height / 2
      const radius = 500

      context.beginPath()
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
      context.fillStyle = '#333'
      context.fill()

      const latarJangkauanRadar = canvas.toDataURL()

      const lenRadarAktif = update.length
      const radarAktif = {}

      for (let i = 0; i < lenRadarAktif; i++) {
        let radar = update[i]
        grupHistoryGambarRadar.value[radar.radar_id] = {}
        radarAktif[radar.radar_id] = {}
        radarAktif[radar.radar_id].radar_name = radar.radar_name
        radarAktif[radar.radar_id].radar_location = [
          radar.radar_lat,
          radar.radar_lon
        ]
        radarAktif[radar.radar_id].radar_bound = [
          [radar.north, radar.west],
          [radar.south, radar.east]
        ]
        radarAktif[radar.radar_id].radar_city = radar.radar_location_city
        radarAktif[radar.radar_id].radar_description =
          radar.radar_location_description
        radarAktif[radar.radar_id].radar_alias = radar.radar_alias
        radarAktif[radar.radar_id].radar_zone = radar.radar_zone

        store.dispatch('setGrupGambarRadar', radar.radar_id)
        store.dispatch('tandaiLokasiRadar', [
          radar.radar_id,
          [radar.radar_lat, radar.radar_lon],
          radar.radar_location_city,
          radar.radar_location_description
        ])
        store.dispatch('tambahLatarJangkauanRadar', [
          latarJangkauanRadar,
          [
            [radar.north, radar.west],
            [radar.south, radar.east]
          ]
        ])
      }

      store.dispatch('setObjekRadarAktif', radarAktif)
    })

    const dataLokasiRadar = computed(() => fetchLokasiRadar.value)

    const pilihLokasiRadar = (lat, lon) => {
      mapDiv.value.flyTo([lat, lon], 10, { animate: false })
    }

    return { dataLokasiRadar, pilihLokasiRadar }
  }
}
</script>

<style>
.setting-location {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radar-location {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
}

.btn-location {
  padding: 0.5rem 1rem;
  background-color: #fff3e5;
  border-radius: 1rem;
}

.btn-location:hover {
  background-color: #20c997;
  color: #fff;
}

.tooltip-lokasi-radar {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
}

.tooltip-header {
  color: #107c5c;
}

@media (min-width: 600px) {
  .radar-location {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
  }
}
</style>
