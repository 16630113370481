const actions = {
  menampilkanMap({ commit }, payload) {
    commit('inisiasiLeaflet', payload)
  },
  setObjekRadarAktif({ commit }, payload) {
    commit('setObjekRadarAktif', payload)
  },
  setGrupGambarRadar({ commit }, payload) {
    commit('setGrupGambarRadar', payload)
  },
  updateDefaultRadarAktif({ commit }, payload) {
    commit('updateDefaultRadarAktif', payload)
  },
  tandaiLokasiRadar({ commit }, payload) {
    commit('tandaiLokasiRadar', payload)
  },
  tambahLatarJangkauanRadar({ commit }, payload) {
    commit('tambahLatarJangkauanRadar', payload)
  },
  tambahGambarRadar({ commit }, payload) {
    commit('tambahGambarRadar', payload)
  },
  updateWaktuGambarRadar({ commit }, payload) {
    commit('updateWaktuGambarRadar', payload)
  },
  resetGrupHistoryGambarRadar({ commit }) {
    commit('resetGrupHistoryGambarRadar')
  },
  tambahAreaPantauan({ commit }, payload) {
    commit('tambahAreaPantauan', payload)
  },
  tambahAreaDAS({ commit }) {
    commit('tambahAreaDAS')
  },
  ubahStatusQPE({ commit }, payload) {
    commit('ubahStatusQPE', payload)
  }
}

export default actions
