<template>
  <div class="setting-history">
    <div class="informasi-waktu">
      <div>
        <p><em>Klik tombol realtime apabila ingin update data terakhir dari radar secara berkelanjutan (tombol akan berwarna hijau apabila sudah aktif)</em></p>
        <button
          class="btn realtime"
          :class="{ 'realtime-active': isUpdating }"
          @click="updateDataGambar()"
        >
          Realtime
        </button>
      </div>
      <div>
        <p><em>Pilih tanggal dan waktu yang ingin dilihat dan tekan tombol "Pilih". Apabila sudah sesuai dengan yang diinginkan silahkan tekan tombol "Submit"</em></p>
        <div class="pemilihan-waktu">
          <Datepicker
            v-model="date"
            @update:modelValue="submitPermission()"
            class="datepicker-form"
            :time-picker-component="timePicker"
            :minDate="new Date(2020, 12)"
            :maxDate="waktuSaatIni"
            :format="format"
            :previewFormat="format"
            cancelText="Batal"
            selectText="Pilih"
          />
          <button class="btn btn-submit" :class="{ 'btn-disabled': !dapatSubmitHistory }" @click="submitWaktuTerpilih()">
            Submit
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="slider">
      <input type="range" min="0" :max="jumlahHistoryGambarRadar" v-model="indexHistoryGambar" />
      <span>{{ indexHistoryGambar }}</span>
    </div> -->
    <div v-show="aktifkanPlayer">
      <!-- <p><em>Harap menunggu pemrosesan data hingga selesai (animasi berjalan dengan lancar)</em></p> -->
      <div class="sequence-container" @click="setProgressSequence($event)">
        <div class="sequence" :style="`width: ${widthSequence}%`"></div>
      </div>
    </div>
    <span class="sequence-time">{{ waktuGambarRadar }}</span>
    <div class="data-info" v-show="dataTidakTersedia"><span><strong>Data tidak tersedia</strong></span></div>
    <div class="player-container" v-show="aktifkanPlayer">
      <fa
        class="menu-icon player-control"
        icon="backward"
        title="Backward"
        @click="prevGambar()"
      />
      <fa
        class="menu-icon player-control btn-play"
        :icon="isPlaying ? 'pause' : 'play'"
        :title="isPlaying ? 'Pause' : 'Play'"
        @click="playAnimasi()"
      />
      <fa
        class="menu-icon player-control"
        icon="forward"
        title="Forward"
        @click="nextGambar()"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, watch, defineAsyncComponent, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

const TimePicker = defineAsyncComponent(() => import('./TimePickerCustom.vue'))

export default {
  components: { Datepicker },
  setup() {
    const store = useStore()

    const objekRadarAktif = computed(() => store.getters.objekRadarAktif)
    const waktuGambarRadar = computed(() => store.getters.waktuGambarRadar)
    const waktuSaatIni = computed(() => store.getters.waktuSaatIni)
    const grupGambarRadar = computed(() => store.getters.grupGambarRadar)
    const tandaLokasiRadar = computed(() => store.getters.tandaLokasiRadar)

    const pathGambarTerakhir = ref({})
    const waktuGambarTerakhir = ref({})

    const statusQPE = computed(() => store.getters.statusQPE)
    const modeGambar = ref('/dbz')

    watch(statusQPE, (update) => {
      // if (isUpdating.value != true) {/
      isUpdating.value = false
      updateGambar()
      isUpdating.value = true
      isPlaying.value = false
      // }
      if (update == true) modeGambar.value = '/qpe'
      if (update == false) modeGambar.value = '/dbz'
      pathGambarTerakhir.value = {}
      waktuGambarTerakhir.value = {}
      updateGambar()
    })

    const isUpdating = ref(true)
    let updating = null

    const updateGambar = () => {
      if (!isUpdating.value) {
        clearTimeout(updating)
        updating = null
      }
      if (isUpdating.value) {
        // console.log('Start')
        axios.get('/path-mat').then(({ data }) => {
        // axios.get('/test-path').then(({ data }) => {
          const lenPathGambar = data.length
          let listGrupGambarRadarBelumUpdate = Object.keys(
            grupGambarRadar.value
          )

          for (let item = 0; item < lenPathGambar; item++) {
            let pathGambar = "/api" + modeGambar.value + data[item].path + '/' + data[item].filename.slice(0,-4)
            // let pathGambar = "http://127.0.0.1:8000" + modeGambar.value + data[item].path + '/' + data[item].filename.slice(0,-4)

            let index = listGrupGambarRadarBelumUpdate.indexOf(
              data[item].radar_id.toString()
            )
            if (index > -1) {
              listGrupGambarRadarBelumUpdate.splice(index, 1)
            }

            if (pathGambarTerakhir.value[data[item].radar_id] == pathGambar) {
              if (grupGambarRadar.value[data[item].radar_id].getLayers() == false && data[item].filename != null) {
                store.dispatch('tambahGambarRadar', [
                  pathGambar,
                  objekRadarAktif.value[data[item].radar_id].radar_bound,
                  data[item].radar_id, // id radar
                  1 // mode tambah gambar
                ])
              }
              continue
            }
            if (data[item].filename == null) continue

            store.dispatch('tambahGambarRadar', [
              pathGambar,
              objekRadarAktif.value[data[item].radar_id].radar_bound,
              data[item].radar_id, // id radar
              1 // mode tambah gambar
            ])

            // console.log(item + ' last ' + pathGambarTerakhir.value[data[item].radar_id])

            pathGambarTerakhir.value[data[item].radar_id] = pathGambar
            waktuGambarTerakhir.value[data[item].radar_id] = data[item].datetime
            
            tandaLokasiRadar.value[data[item].radar_id].setStyle({color: '#000000'})
            if (moment.utc().diff(moment(data[item].datetime + "Z"), "minutes") <= 2) {
              tandaLokasiRadar.value[data[item].radar_id].setStyle({color: '#ff0000'})
            }

            // console.log(item + ' now ' + pathGambar)
          }
          // console.log(listGrupGambarRadarBelumUpdate)
          store.dispatch(
            'updateWaktuGambarRadar',
            // moment(data.at(0).datetime).format('D MMMM YYYY, HH:mm')
            moment.utc(data.at(0).datetime).local().format('D MMMM YYYY, HH:mm')
            )
          // console.log(data.at(0).datetime + 'Z')
          // console.log(moment.utc().format())

          for (let item in listGrupGambarRadarBelumUpdate) {
            if (waktuGambarTerakhir.value[listGrupGambarRadarBelumUpdate[item]] != null) {
              let interval = moment(data.at(0).datetime).diff(moment(waktuGambarTerakhir.value[listGrupGambarRadarBelumUpdate[item]] + "Z"), "minutes")
              if (interval <= 2) continue
            }

            tandaLokasiRadar.value[listGrupGambarRadarBelumUpdate[item]].setStyle({color: '#000000'})

            grupGambarRadar.value[
              listGrupGambarRadarBelumUpdate[item]
            ].clearLayers()
          }
        })
        // console.log('Stop')
        updating = setTimeout(updateGambar, 10000)
      }
    }

    const updateDataGambar = () => {
      if (isUpdating.value != true) {
        isUpdating.value = true
        isPlaying.value = false
        pathGambarTerakhir.value = {}
        waktuGambarTerakhir.value = {}
        updateGambar()
      }
    }

    const pathHistoryGambarRadar = ref([])
    const listWaktuRadar = ref([])
    const jumlahHistoryGambarRadar = ref(1)

    const grupHistoryGambarRadar = computed(
      () => store.getters.grupHistoryGambarRadar
    )

    const indexHistoryGambar = ref(0)
    const widthSequence = computed(
      () => (indexHistoryGambar.value / jumlahHistoryGambarRadar.value) * 100
    )

    watch(indexHistoryGambar, (update) => {
      // console.log(grupHistoryGambarRadar.value)
      // console.log(update)
      isUpdating.value = false

      const keyGrupGambarRadar = Object.keys(grupGambarRadar.value)
      const lenKeyGrupGambarRadar = keyGrupGambarRadar.length

      let listGrupGambarRadarBelumUpdate = Object.keys(grupGambarRadar.value)

      for (let item = 0; item < lenKeyGrupGambarRadar; item++) {
        if (grupHistoryGambarRadar.value[keyGrupGambarRadar[item]][listWaktuRadar.value[update]] != null) {
          let index = listGrupGambarRadarBelumUpdate.indexOf(
            keyGrupGambarRadar[item].toString()
          )
          if (index > -1) {
            listGrupGambarRadarBelumUpdate.splice(index, 1)
          }

          tandaLokasiRadar.value[keyGrupGambarRadar[item]].setStyle({color: '#ff0000'})

          store.dispatch('tambahGambarRadar', [
            grupHistoryGambarRadar.value[keyGrupGambarRadar[item]][listWaktuRadar.value[update]],
            objekRadarAktif.value[keyGrupGambarRadar[item]].radar_bound,
            keyGrupGambarRadar[item], // id radar
            3 // mode tambah gambar
          ])
        }
      }

      for (let item in listGrupGambarRadarBelumUpdate) {
        grupGambarRadar.value[
          listGrupGambarRadarBelumUpdate[item]
        ].clearLayers()
        tandaLokasiRadar.value[listGrupGambarRadarBelumUpdate[item]].setStyle({color: '#000000'})
      }

      store.dispatch(
        'updateWaktuGambarRadar',
        moment.utc(listWaktuRadar.value[update]).local().format('D MMMM YYYY, HH:mm')
      )
      // waktuGambarRadar.value = pathHistoryGambarRadar.value[update].datetime_utc
    })

    const date = ref(new Date())

    // console.log(date.value.toLocaleString('en-US', { timeZone: 'Asia/Jayapura' }))
    // console.log(moment().tz())
    // console.log(moment().toDate())
    // console.log(moment().format('D MMMM YYYY, HH:mm:ss'))
    // console.log(moment(date.value).format('D MMMM YYYY, HH:mm:ss'))

    const format = (date) => {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hour = date.getHours()

      return `${day}/${month}/${year} ${hour}:00`
    }

    const timePicker = computed(() => TimePicker)

    const dapatSubmitHistory = ref(true)
    const submitPermission = () => {
      dapatSubmitHistory.value = true
    }

    const submitWaktuTerpilih = async () => {
      dapatSubmitHistory.value = false
      isUpdating.value = false
      isPlaying.value = false
      indexHistoryGambar.value = 0
      listWaktuRadar.value = []

      if (date.value == null) return

      const year = date.value.getUTCFullYear()
      const month = date.value.getUTCMonth() + 1
      const day = date.value.getUTCDate()
      const hour = date.value.getUTCHours()

      // console.log(`${year}/${month}/${day}/${hour}`)
      // console.log(date.value.toISOString().slice(0,-10)+"00:00")
      // console.log(date.value.toISOString())
      // console.log(hour)

      // console.log('Start History')
      store.dispatch('resetGrupHistoryGambarRadar')

      await axios
        .get(
          `/history-path-mat?tahun=${year}&bulan=${month}&tanggal=${day}&jam=${hour}`
        )
        .then(({ data }) => {
          const lenPathGambar = data.length
          if (lenPathGambar == 0) {
            aktifkanPlayer.value = false
            return dataTidakTersedia.value = true
          }
          pathHistoryGambarRadar.value = data
          // console.log(pathHistoryGambarRadar.value)

          for (let item = 0; item < lenPathGambar; item++) {
            let pathGambar = "/api/dbz" + data[item].path + '/' + data[item].filename.slice(0,-4)
            // let pathGambar = "http://127.0.0.1:8000/dbz" + data[item].path + '/' + data[item].filename.slice(0,-4)

            let waktu = data[item].datetime

            store.dispatch('tambahGambarRadar', [
              pathGambar,
              objekRadarAktif.value[data[item].radar_id].radar_bound,
              data[item].radar_id,
              2,
              waktu
            ])

            listWaktuRadar.value.push(waktu)
          }

          listWaktuRadar.value = [...new Set(listWaktuRadar.value)]
          listWaktuRadar.value.sort()
          jumlahHistoryGambarRadar.value = listWaktuRadar.value.length - 1
          // console.log(listWaktuRadar.value)
          setTimeout(() => {
            if (listWaktuRadar.value.length != 0) {
              isPlaying.value = true
              dataTidakTersedia.value = false
              aktifkanPlayer.value = true
              animasiGambar()
            }
          }, 3000)
        })
      // console.log('Stop History')
    }

    const dataTidakTersedia = ref(false)
    const aktifkanPlayer = ref(false)

    const isPlaying = ref(false)
    let playing = null

    const animasiGambar = () => {
      if (isPlaying.value) {
        if (indexHistoryGambar.value == jumlahHistoryGambarRadar.value)
          indexHistoryGambar.value = 0
        else indexHistoryGambar.value++
        playing = setTimeout(animasiGambar, 500)
      }
      if (!isPlaying.value) {
        clearTimeout(playing)
        playing = null
      }
    }

    const playAnimasi = () => {
      isPlaying.value = !isPlaying.value
      animasiGambar()
    }

    const prevGambar = () => {
      if (isPlaying.value) isPlaying.value = false
      if (indexHistoryGambar.value == 0) return indexHistoryGambar.value = jumlahHistoryGambarRadar.value
      indexHistoryGambar.value--
    }

    const nextGambar = () => {
      if (isPlaying.value) isPlaying.value = false
      if (indexHistoryGambar.value == jumlahHistoryGambarRadar.value) return indexHistoryGambar.value = 0
      indexHistoryGambar.value++
    }

    const setProgressSequence = (event) => {
      if (isPlaying.value) isPlaying.value = false
      const width = event.srcElement.clientWidth
      const clickX = event.offsetX + 1
      indexHistoryGambar.value = Math.floor(clickX/width*jumlahHistoryGambarRadar.value)
      // console.log(clickX)
      // console.log(width)
      // console.log(indexHistoryGambar.value)
      // console.log(event)
    }

    onMounted(() => {
      setTimeout(updateGambar, 1000)
    })

    return {
      waktuGambarRadar,
      waktuSaatIni,
      isUpdating,
      updateDataGambar,
      indexHistoryGambar,
      widthSequence,
      listWaktuRadar,
      jumlahHistoryGambarRadar,
      date,
      format,
      timePicker,
      dapatSubmitHistory,
      submitPermission,
      submitWaktuTerpilih,
      dataTidakTersedia,
      aktifkanPlayer,
      isPlaying,
      playAnimasi,
      prevGambar,
      nextGambar,
      setProgressSequence
    }
  }
}
</script>

<style>
.setting-history {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.setting-history p {
  margin-bottom: 2px;
}

.setting-history p em {
  font-size: 1rem;
}

.informasi-waktu {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.realtime {
  /* background-color: #bde4a7; */
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 5px;
}

.realtime-active {
  background-color: #bde4a7;
}

.pemilihan-waktu {
  display: flex;
  gap: 1rem;
  align-items: stretch;
}

.datepicker-form {
  flex-grow: 1;
}

.btn-submit {
  background-color: #fcdf6b;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.btn-submit.btn-disabled {
  background-color: #fff5ce;
  pointer-events: none;
}

.sequence-container {
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  height: 4px;
  width: 100%;
}

.sequence {
  background: #242323;
  border-radius: 5px;
  height: 100%;
  transition: width 0.1s linear;
}

.data-info {
  display: flex;
  justify-content: center;
}

.sequence-time {
  display: flex;
  justify-content: center;
}

.player-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.player-control {
  font-size: 2rem;
  cursor: pointer;
}

.btn-play {
  font-size: 3rem;
}

.player-control:hover {
  color: #f1bf98;
}
</style>
