<template>
  <TheMap />
  <TheLegend />
  <TheSetting />
  <TheFooter />
</template>

<script>
import TheMap from '@/components/TheMap.vue'
import TheLegend from '@/components/TheLegend.vue'
import TheSetting from '@/components/TheSetting.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'Home',
  components: {
    TheMap,
    TheLegend,
    TheSetting,
    TheFooter
  }
}
</script>
