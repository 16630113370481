<template>
  <footer class="footer noselect">
    <span>&copy; 2022 - BRIN</span>
    <span class="tanggal-waktu">{{ waktuSaatIni }}</span>
    <span>
      <a class="leaflet--link" href="https://leafletjs.com" target="_blank"
        >Leaflet</a
      >
    </span>
    <div style="display: none">{{ updateWaktu() }}</div>
  </footer>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

export default {
  setup() {
    const store = useStore()

    const waktuSaatIni = ref(moment().format('D MMMM YYYY, HH:mm:ss'))

    const updateWaktu = () => {
      waktuSaatIni.value = moment().format('D MMMM YYYY, HH:mm:ss')
      store.dispatch('updateWaktu', waktuSaatIni.value)
      setTimeout(updateWaktu, 1000)
    }

    return {
      waktuSaatIni,
      updateWaktu
    }
  }
}
</script>

<style>
.footer {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  height: 2.4rem;
  background-color: #E9FAE3;
  border-top: 2px solid rgba(0,0,0,.2);
}

.tanggal-waktu {
  font-weight: 600;
}

.leaflet--link:link,
.leaflet--link:visited {
  text-decoration: none;
}
</style>
