<template>
  <div class="control-menu">
    <div class="menu-container noselect" v-show="statusMenu">
      <menu class="menu">
        <button
          v-for="menu in pilihanMenu"
          :key="menu.id"
          @click="pilihMenu(menu.id)"
          class="btn btn-menu"
          :class="{ 'menu-active': menuTerpilih === menu.id }"
        >
          <fa class="menu-icon" :icon="menu.icon" />
          <span class="menu-name">{{ menu.nama }}</span>
        </button>
      </menu>
      <div class="menu-setting">
        <setting-location v-show="menuTerpilih === 1"></setting-location>
        <setting-area v-show="menuTerpilih === 2"></setting-area>
        <setting-opacity v-show="menuTerpilih === 3"></setting-opacity>
        <setting-history v-show="menuTerpilih === 4"></setting-history>
        <!-- <setting-estimation v-show="menuTerpilih === 5"></setting-estimation> -->
      </div>
    </div>
    <div class="header-control">
      <button class="btn btn-control" @click="bukaMenu()">
        <svg
          v-show="!statusMenu"
          xmlns="http://www.w3.org/2000/svg"
          class="btn-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <!-- <svg
        v-show="!statusMenu"
        xmlns="http://www.w3.org/2000/svg"
        class="btn-icon"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 11l7-7 7 7M5 19l7-7 7 7"
        />
      </svg> -->
        <svg
          v-show="statusMenu"
          xmlns="http://www.w3.org/2000/svg"
          class="btn-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <!-- <svg
        v-show="statusMenu"
        xmlns="http://www.w3.org/2000/svg"
        class="btn-icon"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
        />
      </svg> -->
      </button>
      <span
        class="update-terakhir-radar noselect"
        v-show="(menuTerpilih !== 4 && statusMenu) || !statusMenu"
        >{{ waktuGambarRadar }}</span
      >
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

import SettingLocation from '@/components/settings/SettingLocation.vue'
import SettingArea from '@/components/settings/SettingArea.vue'
import SettingOpacity from '@/components/settings/SettingOpacity.vue'
import SettingHistory from '@/components/settings/SettingHistory.vue'
// import SettingEstimation from '@/components/settings/SettingEstimation.vue'

export default {
  components: {
    SettingLocation,
    SettingArea,
    SettingOpacity,
    SettingHistory
    // SettingEstimation
  },
  setup() {
    const store = useStore()

    const statusMenu = ref(false)

    const bukaMenu = function () {
      statusMenu.value = !statusMenu.value
    }

    const pilihanMenu = ref([
      { id: 1, nama: 'Lokasi Radar', icon: 'map-marker-alt' },
      { id: 2, nama: 'Area Pantauan', icon: 'chart-area' },
      { id: 3, nama: 'Pengaturan Opacity', icon: 'eye' },
      { id: 4, nama: 'History', icon: 'history' }
      // { id: 5, nama: 'Estimasi Hujan', icon: 'crosshairs' }
    ])

    const menuTerpilih = ref(1)

    const pilihMenu = function (value) {
      menuTerpilih.value = value
    }

    const waktuGambarRadar = computed(() => store.getters.waktuGambarRadar)

    return {
      statusMenu,
      bukaMenu,
      pilihanMenu,
      menuTerpilih,
      pilihMenu,
      waktuGambarRadar
    }
  }
}
</script>

<style>
.control-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 6rem;
  width: 100%;
  z-index: 30;
}

.header-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 0.75rem 1rem;
  width: 100%;
  transform: translate(0, 100%);
  pointer-events: none;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  transition: background-color 300ms ease-out, stroke 300ms ease-out;
}

.btn-control {
  padding: 0.5rem;
  background-color: #e1f4cb;
  border-radius: 50%;
  box-shadow: 0 0 3px #333;
  pointer-events: auto;
}

.btn-control:hover {
  background-color: #20c997;
}

.btn-icon {
  width: 2.4rem;
  height: 2.4rem;
  stroke: #333;
}

.btn:hover .btn-icon {
  stroke: #fff;
}

.update-terakhir-radar {
  align-self: flex-start;
  color: #fff;
  text-shadow: -0.75px -0.75px 0 #000, 0.75px -0.75px 0 #000,
    -0.75px 0.75px 0 #000, 0.75px 0.75px 0 #000;
}

.btn-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 2rem;
  background-color: #e1f4cb;
  border-radius: 0.5rem;
}

.btn-menu:hover {
  background-color: #20c997;
  color: #fff;
}

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 25rem;
  overflow: hidden;
}

.menu {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f6fef7;
}

.menu .menu-icon {
  font-size: 1.4rem;
}

.menu .menu-name {
  display: none;
}

.menu-active {
  background-color: #f1bf98;
}

.menu-setting {
  flex-grow: 1;
  padding: 1rem 2rem;
  overflow-y: auto;
  background-color: #fff;
}

@media (min-width: 650px) {
  .control-menu {
    top: initial;
    bottom: 2.4rem;
  }

  .header-control {
    transform: translate(0, -100%);
    top: 0;
    bottom: initial;
  }

  .update-terakhir-radar {
    align-self: flex-end;
    font-size: 1.6rem;
    letter-spacing: 1px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }

  .menu {
    justify-content: flex-start;
  }

  .menu .menu-name {
    display: inline-block;
  }
}
</style>
