<template>
  <div class="map" ref="map"></div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, onMounted } from 'vue'

export default {
  setup() {
    const store = useStore()

    const map = ref(null)

    onMounted(() => {
      store.dispatch('menampilkanMap', map.value)
    })

    return {
      map
    }
  }
}
</script>

<style>
.map {
  flex-grow: 1;
  background-color: #f5f6f4;
  z-index: 10;
}
</style>
