import getters from './getters.js'
import actions from './actions.js'
import mutations from './mutations.js'

const state = {
  pusatMap: [-6.698224, 107.337788], // Titik pertengahan antara titik pusat radar sumedang dan cibinong
  batasanMap: [
    [8, 93],
    [-13.2, 143.8]
  ],
  minZoom: 5,
  maxZoom: 18,
  startZoom: window.innerWidth <= 500 ? 8 : 9,
  mapDiv: null,
  defaultRadarAktif: [
    {
      radar_id: 1,
      radar_name: 'Pasteur, Bandung',
      radar_lat: -6.894941,
      radar_lon: 107.58648,
      radar_location_city: 'Bandung',
      radar_location_description: 'Kantor PSTA',
      radar_status: 1,
      radar_alias: 'bdg1',
      radar_zone: 'GMT+7',
      north: -6.4994,
      south: -7.2905,
      west: 107.191,
      east: 107.982,
      treshold: 375
    },
    {
      radar_id: 2,
      radar_name: 'Tanjung Sari, Sumedang',
      radar_lat: -6.91174,
      radar_lon: 107.8376,
      radar_location_city: 'Sumedang',
      radar_location_description: 'Balai Pengamatan Sumedang',
      radar_status: 1,
      radar_alias: 'smd1',
      radar_zone: 'GMT+7',
      north: -6.5162,
      south: -7.3073,
      west: 107.4421,
      east: 108.2331,
      treshold: 0
    },
    {
      radar_id: 3,
      radar_name: 'Pontianak',
      radar_lat: -0.003386,
      radar_lon: 109.367566,
      radar_location_city: 'Pontianak',
      radar_location_description: 'Kantor Pengnamatan Pontianak',
      radar_status: 1,
      radar_alias: 'ptk1',
      radar_zone: 'GMT+7',
      north: 0.3921,
      south: -0.3989,
      west: 108.972,
      east: 109.7631,
      treshold: 0
    },
    {
      radar_id: 4,
      radar_name: 'Kototabang',
      radar_lat: -0.203639,
      radar_lon: 100.31853,
      radar_location_city: 'Kototabang',
      radar_location_description: 'BPAA Kototabang',
      radar_status: 1,
      radar_alias: 'ktb1',
      radar_zone: 'GMT+7',
      north: 0.1919,
      south: -0.5992,
      west: 99.923,
      east: 100.7141,
      treshold: 0
    },
    {
      radar_id: 10,
      radar_name: 'Sukabumi',
      radar_lat: -6.932948,
      radar_lon: 106.774211,
      radar_location_city: 'Sukabumi',
      radar_location_description: 'BPBD Kota Sukabumi',
      radar_status: 1,
      radar_alias: '0009',
      radar_zone: 'GMT+7',
      north: -6.5374,
      south: -7.3285,
      west: 106.3787,
      east: 107.1697,
      treshold: 600
    },
    {
      radar_id: 11,
      radar_name: 'Bima',
      radar_lat: -8.465563,
      radar_lon: 118.763607,
      radar_location_city: 'Bima',
      radar_location_description: 'BPBD Kota Bima',
      radar_status: 1,
      radar_alias: '0010',
      radar_zone: 'GMT+9',
      north: -8.07,
      south: -8.8611,
      west: 118.3681,
      east: 119.1591,
      treshold: 0
    },
    {
      radar_id: 12,
      radar_name: 'Sorong',
      radar_lat: -0.881205,
      radar_lon: 131.287939,
      radar_location_city: 'Sorong',
      radar_location_description: 'Sorong',
      radar_status: 1,
      radar_alias: '0011',
      radar_zone: 'GMT+8',
      north: -0.4857,
      south: -1.2767,
      west: 130.8924,
      east: 131.6835,
      treshold: 0
    },
    {
      radar_id: 14,
      radar_name: 'Pameungpeuk, Garut',
      radar_lat: -7.650142,
      radar_lon: 107.692125,
      radar_location_city: 'Garut',
      radar_location_description: 'Kantor BPAA Garut',
      radar_status: 1,
      radar_alias: '0012',
      radar_zone: 'GMT+7',
      north: -7.2546,
      south: -8.0457,
      west: 107.2966,
      east: 108.0876,
      treshold: 0
    }
  ],
  objekRadarAktif: {},
  tandaLokasiRadar: {},
  grupLokasiRadar: null,
  grupJangkauanRadar: null,
  grupAreaPantauan: null,
  grupAreaDAS: null,
  grupGambarRadar: {},
  grupHistoryGambarRadar: {},
  waktuGambarRadar: null,
  statusQPE: false
}

export default {
  state,
  getters,
  actions,
  mutations
}
