<template>
  <div class="legend dbz noselect" :class="{ 'off-screen': statusQPE }">
    <span>dBz</span>
    <!-- <span>5</span>
    <span>10</span>
    <span>15</span>
    <span>20</span>
    <span>25</span>
    <span>30</span>
    <span>35</span>
    <span>40</span>
    <span>45</span>
    <span>50</span>
    <span>55</span>
    <span>60</span>
    <span>65</span>
    <span>70</span> -->
    <span>3</span>
    <span>6</span>
    <span>9</span>
    <span>12</span>
    <span>15</span>
    <span>18</span>
    <span>21</span>
    <span>24</span>
    <span>27</span>
    <span>30</span>
    <span>33</span>
    <span>36</span>
    <span>39</span>
    <span>42</span>
    <span>&nbsp;</span>
  </div>
  <div class="legend qpe noselect" :class="{ 'off-screen': !statusQPE }">
    <span>&times;10<sup>-2</sup></span>
    <span>mm</span>
    <!-- <span>&nbsp;</span> -->
    <span>2.5</span>
    <span>5</span>
    <span>7.5</span>
    <span>10</span>
    <span>12.5</span>
    <span>15</span>
    <span>17.5</span>
    <span>&nbsp;</span>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  setup() {
    const store = useStore()

    const statusQPE = computed(() => store.getters.statusQPE)

    return { statusQPE }
  }
}
</script>

<style>
.legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aquamarine;
  position: absolute;
  bottom: 6rem;
  right: 1rem;
  border-radius: 10rem;
  box-shadow: 0 0 0.3rem #333;
  z-index: 20;
  cursor: default;
}

.dbz {
  background: linear-gradient(
    to bottom,
    #04fdff,
    #04fdff,
    #04fdff,
    #0097fe,
    #0100fe,
    #00ff03,
    #02c803,
    #029401,
    #fffd00,
    #ffc603,
    #fd7801,
    #ff0101,
    #c80000,
    #970102,
    #ff02ff,
    #9300fc,
    #9300fc
  );
}

.qpe {
  background: linear-gradient(
    to bottom,
    /* #808080, */
    #eeeeee,
    #eeeeee,
    #eeeeee,
    #eeeeee,
    #00FF00,
    #ffff00,
    #FFA500,
    #FF0000,
    #800080,
    #0000FF,
    #0000FF
  );
}

.legend span {
  flex: 1 1 auto;
  padding: 0.2rem;
  font-size: 1rem;
  font-weight: 500;
  transform: translate(0, 50%);
  /* text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff; */
  text-shadow: -0.075rem -0.075rem 0 #fff, 0.075rem -0.075rem 0 #fff,
    -0.075rem 0.075rem 0 #fff, 0.075rem 0.075rem 0 #fff;
}

.off-screen {
  display: none;
}

@media (min-width: 650px) {
  .legend {
    top: 7rem;
    bottom: initial;
  }
}
</style>
